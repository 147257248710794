export default {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  USER_INFO_LOADED: 'USER_INFO_LOADED',
  USER_DECKS_LOADED: 'USER_DECKS_LOADED',
  USER_CREATED: 'USER_CREATED',
  Errors: {
    UNAUTHENTICATED: 'UNAUTHENTICATED',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    LOAD_DECK_FAILURE: 'LOAD_DECK_FAILURE',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',
    USER_CREATE_FAILURE: 'USER_CREATE_FAILURE'
  }
};
