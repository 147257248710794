export default {
  JOIN_GAME: 'JOIN',
  CREATE_GAME: 'CREATE_GAME',
  GAME_STATUS: 'GAME_STATUS',
  GAME_STARTED: 'START_GAME',
  LOAD_GAME: 'LOAD_GAME',
  PLAY_CARD: 'PLAY_CARD',
  REVEAL_PLAY: 'REVEAL',
  CHOOSE_WINNER: 'CHOOSE_WINNER',
  END_ROUND: 'END_ROUND'
};
