export default {
  DECKS_LOADED: 'DECKS_LOADED',
  DECK_DETAILS_LOADED: 'DECK_DETAILS_LOADED',
  DECK_PACKAGE_ADDED: 'DECK_PACKAGE_ADDED',
  DECK_PACKAGE_REMOVED: 'DECK_PACKAGE_REMOVED',
  DECK_CARD_ADDED: 'DECK_CARD_ADDED',
  DECK_CARD_REMOVED: 'DECK_CARD_REMOVED',
  DECK_DELETED: 'DECK_DELETED',
  DECK_CREATED: 'DECK_CREATED',
  Errors: {
    LOAD_DECK_FAILURE: 'LOAD_DECK_FAILURE',
    LOAD_DECK_DETAILS_FAILURE: 'LOAD_DECK_DETAILS_FAILURE',
    DECK_REMOVE_PACKAGE_FAILURE: 'DECK_REMOVE_PACKAGE_FAILURE',
    DECK_ADD_PACKAGE_FAILURE: 'DECK_ADD_PACKAGE_FAILURE',
    DECK_REMOVE_CARD_FAILURE: 'DECK_REMOVE_CARD_FAILURE',
    DECK_ADD_CARD_FAILURE: 'DECK_ADD_CARD_FAILURE',
    DECK_CREATE_FAILURE: 'DECK_CREATE_FAILURE',
    DECK_REMOVE_FAILURE: 'DECK_REMOVE_FAILURE'
  }
};
