export default {
  PACKAGES_LOADED: 'PACKAGES_LOADED',
  DECK_PACKAGES_LOADED: 'DECK_PACKAGES_LOADED',
  PACKAGE_DETAILS_LOADED: 'PACKAGE_DETAILS_LOADED',
  PACKAGE_CREATED: 'PACKAGE_CREATED',
  PACKAGE_DELETED: 'PACKAGE_DELETED',
  CARDS_LOADED: 'CARDS_LOADED',
  CARD_CREATED: 'CARD_CREATED',
  CARD_DELETED: 'CARD_DELETED',
  CARD_UPDATED: 'CARD_UPDATED',
  Errors: {
    LOAD_PACKAGE_FAILURE: 'LOAD_PACKAGE_FAILURE',
    LOAD_DECK_PACKAGE_FAILURE: 'LOAD_DECK_PACKAGE_FAILURE',
    LOAD_PACKAGE_DETAILS_FAILURE: 'LOAD_PACKAGE_DETAILS_FAILURE',
    CREATE_PACKAGE_FAILURE: 'CREATE_PACKAGE_FAILURE',
    DELETE_PACKAGE_FAILURE: 'DELETE_PACKAGE_FAILURE',
    CREATE_CARD_FAILURE: 'CREATE_CARD_FAILURE',
    DELETE_CARD_FAILURE: 'DELETE_CARD_FAILURE',
    UPDATE_CARD_FAILURE: 'UPDATE_CARD_FAILURE'
  }
};
